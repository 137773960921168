import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Banner from '../images/new/banners/calendario.png'
import Cta from '../images/new/banners/calendario-2.png'

import { 
  HeaderBanner,
  calendarImage,
  section,
  content,
  container
} from '../components/styles/Base.styles'

const CalendarioEscolar = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Calendário Escolar | Um reino de aprendizado e conhecimento" />
    <NavBar />
    <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div>
    <div css={[section, content]} style={{ background: '#FAF8F6' }}>
      <div css={container}>
        <h1>Calendário Escolar</h1>
        <div>
          <img css={calendarImage} src={Cta} alt=''  />
        </div>
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default CalendarioEscolar
